<template>
    <div class="page" v-title data-title="统计">
        <div class="weui-tab">
            <div class="weui-navbar">
                <div v-on:click="changeTab(1)" class="weui-navbar__item" v-bind:class="{'weui-bar__item_on':tabIdx === 1}">
                    <span v-if="tabIdx === 1" style="color: #0066FF;">周</span>
                    <span v-else>周</span>
                </div>
                <div v-on:click="changeTab(2)" class="weui-navbar__item" v-bind:class="{'weui-bar__item_on':tabIdx === 2}">
                    <span v-if="tabIdx === 2" style="color: #0066FF;">月</span>
                    <span v-else>月</span>
                </div>
                <div v-on:click="changeTab(3)" class="weui-navbar__item" v-bind:class="{'weui-bar__item_on':tabIdx === 3}">
                    <span v-if="tabIdx === 3" style="color: #0066FF;">季</span>
                    <span v-else>季</span>
                </div>
                <div v-on:click="changeTab(4)" class="weui-navbar__item" v-bind:class="{'weui-bar__item_on':tabIdx === 4}">
                    <span v-if="tabIdx === 4" style="color: #0066FF;">年</span>
                    <span v-else>年</span>
                </div>
            </div>
        </div>
        <br><br>
        <div>
            <div style="float: left; margin-left: 5%; display: inline-flex">
                <div style="width: 7px; height: 7px; border-radius: 50%; background-color: green"></div>
                <div style="margin-left: 3px; font-size: 12px; color: gray; line-height: 7px">氧浓度显示</div>
            </div>
            <div style="float: right; margin-right: 5%; display: inline-flex">
                <div style="width: 7px; height: 7px; border-radius: 50%; background-color: blue"></div>
                <div style="margin-left: 3px; font-size: 12px; color: gray; line-height: 7px">流量</div>
            </div>
        </div>
        <div style="margin-top: 6%; margin-bottom: 10%">
            <div style="float: left; margin-left: 5%; display: inline-flex">
                <div style="width: 7px; height: 7px; border-radius: 50%; background-color: gold"></div>
                <div style="margin-left: 3px; font-size: 12px; color: gray; line-height: 7px">血氧饱和度</div>
            </div>
            <div style="float: right; margin-right: 5%; display: inline-flex">
                <div style="width: 7px; height: 7px; border-radius: 50%; background-color: red"></div>
                <div style="margin-left: 3px;font-size: 12px; color: gray; line-height: 7px">累计时间</div>
            </div>
        </div>
        <br>
        <canvas id="myChart"></canvas>
        <div style="margin:25% auto 5% auto; width:250px;height:50px;border-radius:50px;background-color: #FFFFFF;text-align: center; color: rgba(0,0,0,.5);">
            <i v-on:click="prevTime" class="iconfont icon-zuola" style="float: left; line-height: 50px; font-size: 20px; margin-left: 5px"></i>
            <span style="font-size: 16px; line-height: 50px; color: rgba(0,0,0,.7);"> {{ timeQuantum }} </span>
            <i v-on:click="nextTime" class="iconfont icon-youla" style="float: right; line-height: 50px; font-size: 20px; margin-right: 5px"></i>
        </div>
    </div>
</template>

<script>
    import http from "@/utils/http";

    const tab_arr = ['week', 'month', 'season', 'year'];
    export default {
        name: "Awgels_Statistics",
        data() {
            return {
                deviceNo: this.$route.params.deviceNo,
                tabIdx: 1,
                date: new Date(),
                startTime: this.$moment(this.date).subtract(7, 'd').toDate(),
                endTime: this.$moment(this.date).subtract(1, 'd').toDate(),
                timeQuantum: '',
            }
        },
        mounted() {
            this.timeQuantum = this.$moment(this.startTime).format('DD/MM/YYYY')
                + '-'
                + this.$moment(this.endTime).format('DD/MM/YYYY');
            this.fetchData();
        },
        methods: {
            changeTab(idx) {
                this.tabIdx = idx;
                if (this.tabIdx == 1) {
                    this.startTime = this.$moment(this.date).subtract(7, 'd').toDate();
                    this.endTime = this.$moment(this.date).subtract(1, 'd').toDate();
                    this.timeQuantum = this.$moment(this.startTime).format('DD/MM/YYYY')
                        + '-'
                        + this.$moment(this.endTime).format('DD/MM/YYYY');
                } else if (this.tabIdx == 2) {
                    this.endTime = this.$moment(this.date).toDate();
                    this.timeQuantum = this.$moment(this.endTime).format('MM/YYYY');
                } else if (this.tabIdx == 3) {
                    this.startTime = this.$moment(this.date).subtract(2, 'M').startOf('month').toDate();
                    this.endTime = this.$moment(this.date).toDate();
                    this.timeQuantum = this.$moment(this.startTime).format('MM/YYYY')
                        + '-'
                        + this.$moment(this.endTime).format('MM/YYYY');
                } else {
                    this.startTime = this.$moment(this.date).startOf('year').toDate();
                    this.endTime = this.$moment(this.date).toDate();
                    this.timeQuantum = this.$moment(this.endTime).format('YYYY');
                }
                this.fetchData();
            },
            prevTime() {
                if (this.tabIdx == 1) {
                    let _date = this.startTime;
                    this.startTime = this.$moment(_date).subtract(7, 'd').toDate();
                    this.endTime = this.$moment(_date).subtract(1, 'd').toDate();
                    this.timeQuantum = this.$moment(this.startTime).format('DD/MM/YYYY')
                        + '-'
                        + this.$moment(this.endTime).format('DD/MM/YYYY');
                } else if (this.tabIdx == 2) {
                    let _endTime = this.$moment(this.endTime).subtract(1, 'M').endOf('month').toDate();
                    this.endTime = _endTime;
                    this.timeQuantum = this.$moment(_endTime).format('MM/YYYY');
                } else if (this.tabIdx == 3) {
                    this.startTime = this.$moment(this.startTime).subtract(3, 'M').startOf('month').toDate();
                    this.endTime = this.$moment(this.endTime).subtract(3, 'M').endOf('month').toDate();
                    this.timeQuantum = this.$moment(this.startTime).format('MM/YYYY')
                        + '-'
                        + this.$moment(this.endTime).format('MM/YYYY');
                } else {
                    let _year = this.$moment(this.endTime).subtract(1, 'y').toDate();
                    this.startTime = this.$moment(_year).startOf('year').toDate();
                    this.endTime = this.$moment(_year).endOf('year').toDate();
                    this.timeQuantum = this.$moment(_year).format('YYYY');
                }
                this.fetchData();
            },
            nextTime() {
                if (this.tabIdx == 1) {
                    let _date = this.endTime;
                    let _date1 = this.$moment(this.date).subtract(1, 'd').format('DD/MM/YYYY');
                    let _date2 = this.$moment(_date).format('DD/MM/YYYY');
                    // 判断当天是否结束时间
                    if (_date1 == _date2) {
                        return;
                    }
                    this.startTime = this.$moment(_date).add(1, 'd').toDate();
                    this.endTime = this.$moment(_date).add(7, 'd').toDate();
                    this.timeQuantum = this.$moment(this.startTime).format('DD/MM/YYYY') + '-' + this.$moment(this.endTime).format('DD/MM/YYYY');
                } else if (this.tabIdx == 2) {
                    let _month1 = this.$moment(this.date).format('M');
                    let _month2 = this.$moment(this.endTime).format('M');
                    // 判断当月是否结束时间所在月份
                    if (_month1 == _month2) {
                        return;
                    }
                    // 当月结束时间取当天
                    let _endTime = (_month1 - 1 == _month2)
                        ? this.date
                        : this.$moment(this.endTime).add(1, 'M').endOf('month').toDate();
                    this.endTime = _endTime;
                    this.timeQuantum = this.$moment(_endTime).format('MM/YYYY');
                } else if (this.tabIdx == 3) {
                    let _month1 = this.$moment(this.date).format('MM/YYYY');
                    let _month2 = this.$moment(this.endTime).format('MM/YYYY');
                    // 判断当月是否结束时间所在月份
                    if (_month1 == _month2) {
                        return;
                    }
                    _month1 = this.$moment(this.date).format('M');
                    _month2 = this.$moment(this.endTime).format('M');
                    // 当月结束时间取当天
                    let _endTime = (_month1 - 3 == _month2)
                        ? this.date
                        : this.$moment(this.endTime).add(3, 'M').endOf('month').toDate();
                    this.startTime = this.$moment(this.startTime).add(3, 'M').startOf('month').toDate();
                    this.endTime = _endTime;
                    this.timeQuantum = this.$moment(this.startTime).format('MM/YYYY')
                        + '-'
                        + this.$moment(this.endTime).format('MM/YYYY');
                } else {
                    let _year1 = this.$moment(this.date).format('YYYY');
                    let _year2 = this.$moment(this.endTime).format('YYYY');
                    // 判断当年是否结束时间所在年份
                    if (_year1 == _year2) {
                        return;
                    }
                    // 当年结束时间取当天
                    let _endTime = (_year1 - 1 == _year2)
                        ? this.date
                        : this.$moment(this.endTime).add(1, 'y').endOf('year').toDate();
                    this.startTime = this.$moment(_endTime).startOf('year').toDate();
                    this.endTime = _endTime;
                    this.timeQuantum = this.$moment(_endTime).format('YYYY');
                }
                this.fetchData();
            },
            fetchData() {
                if (this.tabIdx == 1) {
                    http.get('/api/statistics/' + tab_arr[this.tabIdx - 1], {
                        deviceNo: this.deviceNo,
                        timeQuantum: this.timeQuantum
                    }, res => {
                        week_chart(res.data.xAxis, res.data.yAxis, res.data.source);
                    });
                } else if (this.tabIdx == 2) {
                    let timeQuantum = this.$moment(this.endTime).startOf('month').format('DD/MM/YYYY')
                        + '-'
                        + this.$moment(this.endTime).format('DD/MM/YYYY');
                    http.get('/api/statistics/' + tab_arr[this.tabIdx - 1], {
                        deviceNo: this.deviceNo,
                        timeQuantum: timeQuantum
                    }, res => {
                        month_chart(res.data.xAxis, res.data.yAxis, res.data.source);
                    });
                } else if (this.tabIdx == 3) {
                    let timeQuantum = this.$moment(this.startTime).format('DD/MM/YYYY')
                        + '-'
                        + this.$moment(this.endTime).format('DD/MM/YYYY');
                    http.get('/api/statistics/' + tab_arr[this.tabIdx - 1], {
                        deviceNo: this.deviceNo,
                        timeQuantum: timeQuantum
                    }, res => {
                        season_chart(res.data.xAxis, res.data.yAxis, res.data.source);
                    });
                } else {
                    let timeQuantum = this.$moment(this.startTime).format('DD/MM/YYYY')
                        + '-'
                        + this.$moment(this.endTime).format('DD/MM/YYYY');
                    http.get('/api/statistics/' + tab_arr[this.tabIdx - 1], {
                        deviceNo: this.deviceNo,
                        timeQuantum: timeQuantum
                    }, res => {
                        year_chart(res.data.xAxis, res.data.yAxis, res.data.source);
                    });
                }
            }
        }
    }

    const F2 = require('@antv/f2/lib/index');
    const Shape = F2.Shape;
    const ScrollBar = require('@antv/f2/lib/plugin/scroll-bar');
    const pan = require('@antv/f2/lib/interaction/pan');

    Shape.registerShape('point', 'ynd_text', {
        draw: function(cfg, container) {
            // 自定义最终绘制的逻辑
            let _circle = container.addShape('circle', {
                attrs: {
                    x: cfg.x,
                    y: cfg.y,
                    r: 3,
                    fill: cfg.color
                }
            });
            let origin = cfg.origin._origin;
            let _text = container.addShape('text', {
                attrs: {
                    x: cfg.x - 10,
                    y: cfg.y - 3,
                    text: origin.ynd,
                    fill: cfg.color
                }
            });
            return [_circle, _text]; // 返回最后绘制的 shape
        }
    });
    Shape.registerShape('point', 'xy_text', {
        draw: function(cfg, container) {
            // 自定义最终绘制的逻辑
            let _circle = container.addShape('circle', {
                attrs: {
                    x: cfg.x,
                    y: cfg.y,
                    r: 3,
                    fill: cfg.color
                }
            });

            let origin = cfg.origin._origin;
            let _text = container.addShape('text', {
                attrs: {
                    x: cfg.x - 10,
                    y: cfg.y - 3,
                    text: origin.xy,
                    fill: cfg.color
                }
            });
            return [_circle, _text]; // 返回最后绘制的 shape
        }
    });
    Shape.registerShape('point', 'll_text', {
        draw: function(cfg, container) {
            // 自定义最终绘制的逻辑
            let _circle = container.addShape('circle', {
                attrs: {
                    x: cfg.x,
                    y: cfg.y,
                    r: 3,
                    fill: cfg.color
                }
            });

            let origin = cfg.origin._origin;
            let _text = container.addShape('text', {
                attrs: {
                    x: cfg.x - 10,
                    y: cfg.y - 3,
                    text: origin.ll,
                    fill: cfg.color
                }
            });
            return [_circle, _text]; // 返回最后绘制的 shape
        }
    });
    Shape.registerShape('point', 'ct_text', {
        draw: function(cfg, container) {
            // 自定义最终绘制的逻辑
            let _circle = container.addShape('circle', {
                attrs: {
                    x: cfg.x,
                    y: cfg.y,
                    r: 3,
                    fill: cfg.color
                }
            });
            let origin = cfg.origin._origin;
            let _text = container.addShape('text', {
                attrs: {
                    x: cfg.x - 10,
                    y: cfg.y - 3,
                    text: origin.ct,
                    fill: cfg.color
                }
            });
            return [_circle, _text]; // 返回最后绘制的 shape
        }
    });

    function week_chart(xAxis, yAxis, data) {
        // Step 1: 创建 Chart 对象
        const chart = new F2.Chart({
            id: 'myChart',
            width: screen.availWidth,
            height: 300,
            //padding: 'auto', // 默认值，自动计算 padding
            pixelRatio: window.devicePixelRatio // 指定分辨率
        });

        // Step 2: 载入数据源
        chart.source(data);

        // 关闭图例
        chart.legend(false);

        // 设置X轴属性
        chart.axis('time', { label: { fontSize: 12 }, grid: null });
        // 设置Y轴属性
        chart.axis('ynd', {
            label: {
                fontSize: 12
            },
            grid: () => {
                return {
                    lineWidth: 1,
                    lineDash: null,
                    stroke: 'gray'
                }
            }
        });
        chart.axis('xy', { label: { fontSize: 12 }, grid: null });
        chart.axis('ll', { label: { fontSize: 12 }, grid: null });
        chart.axis('ct', { label: { fontSize: 12 }, grid: null });

        // 改变Y轴刻度值
        chart.scale("ynd", {
            min: 0,//手动设置最小值
            ticks: [0, 20, 40, 60, 80, 100, 120],
        });
        chart.scale("xy", {
            min: 0,//手动设置最小值
            ticks: [0, 20, 40, 60, 80, 100, 120],
        });
        chart.scale("ll", {
            ticks: ['0.0', '4.0', '8.0', '12.0', '16.0', '20.0', '24.0'],
        });
        chart.scale("ct", {
            ticks: ['0.0', '4.0', '8.0', '12.0', '16.0', '20.0', '24.0'],
        });
        // 关闭提示
        chart.tooltip(false);

        // Step 3：创建图形
        chart.line().position('time*ynd').color('green').shape('line');
        chart.point().position('time*ynd').color('green').shape('ynd_text');
        chart.line().position('time*xy').color('gold').shape('line');
        chart.point().position('time*xy').color('gold').shape('xy_text');
        chart.line().position('time*ll').color('blue').shape('line');
        chart.point().position('time*ll').color('blue').shape('ll_text');
        chart.line().position('time*ct').color('red').shape('line');
        chart.point().position('time*ct').color('red').shape('ct_text');

        // Step 4: 渲染图表
        chart.render();
    }

    function month_chart(xAxis, yAxis, data) {
        // Step 1: 创建 Chart 对象
        const chart = new F2.Chart({
            id: 'myChart',
            width: screen.availWidth,
            height: 300,
            //padding: 'auto', // 默认值，自动计算 padding
            plugins: [ScrollBar, pan],
            pixelRatio: window.devicePixelRatio // 指定分辨率
        });

        // Step 2: 载入数据源
        chart.source(data);

        // 关闭图例
        chart.legend(false);

        // 设置X轴属性
        chart.axis('time', { label: { fontSize: 12 }, grid: null });
        // 设置Y轴属性
        chart.axis('ynd', {
            label: {
                fontSize: 12
            },
            grid: () => {
                return {
                    lineWidth: 1,
                    lineDash: null,
                    stroke: 'gray'
                }
            }
        });
        chart.axis('xy', { label: { fontSize: 12 }, grid: null });
        chart.axis('ll', { label: { fontSize: 12 }, grid: null });
        chart.axis('ct', { label: { fontSize: 12 }, grid: null });

        // 改变X轴刻度值
        chart.scale("time", {
            ticks: xAxis,
            values: xAxis.slice(-7)
        });
        // 改变Y轴刻度值
        chart.scale("ynd", {
            min: 0,//手动设置最小值
            ticks: [0, 20, 40, 60, 80, 100, 120],
        });
        chart.scale("xy", {
            min: 0,//手动设置最小值
            ticks: [0, 20, 40, 60, 80, 100, 120],
        });
        chart.scale("ll", {
            ticks: ['0.0', '4.0', '8.0', '12.0', '16.0', '20.0', '24.0'],
        });
        chart.scale("ct", {
            ticks: ['0.0', '4.0', '8.0', '12.0', '16.0', '20.0', '24.0'],
        });
        // 关闭提示
        chart.tooltip(false);

        // Step 3：创建图形
        chart.line().position('time*ynd').color('green').shape('line');
        chart.point().position('time*ynd').color('green').shape('ynd_text');
        chart.line().position('time*xy').color('gold').shape('line');
        chart.point().position('time*xy').color('gold').shape('xy_text');
        chart.line().position('time*ll').color('blue').shape('line');
        chart.point().position('time*ll').color('blue').shape('ll_text');
        chart.line().position('time*ct').color('red').shape('line');
        chart.point().position('time*ct').color('red').shape('ct_text');

        // Step 4: 渲染图表
        chart.render();
        chart.interaction("pan");
    }

    function season_chart(xAxis, yAxis, data) {
        // Step 1: 创建 Chart 对象
        const chart = new F2.Chart({
            id: 'myChart',
            width: screen.availWidth,
            height: 300,
            //padding: 'auto', // 默认值，自动计算 padding
            plugins: [ScrollBar, pan],
            pixelRatio: window.devicePixelRatio // 指定分辨率
        });

        // Step 2: 载入数据源
        chart.source(data);

        // 关闭图例
        chart.legend(false);

        // 设置X轴属性
        chart.axis('time', { label: { fontSize: 12 }, grid: null });
        // 设置Y轴属性
        chart.axis('ynd', {
            label: {
                fontSize: 12
            },
            grid: () => {
                return {
                    lineWidth: 1,
                    lineDash: null,
                    stroke: 'gray'
                }
            }
        });
        chart.axis('xy', { label: { fontSize: 12 }, grid: null });
        chart.axis('ll', { label: { fontSize: 12 }, grid: null });
        chart.axis('ct', { label: { fontSize: 12 }, grid: null });

        // 改变X轴刻度值
        chart.scale("time", {
            ticks: xAxis,
            values: xAxis.slice(-7)
        });
        // 改变Y轴刻度值
        chart.scale("ynd", {
            min: 0,//手动设置最小值
            ticks: [0, 20, 40, 60, 80, 100, 120],
        });
        chart.scale("xy", {
            min: 0,//手动设置最小值
            ticks: [0, 20, 40, 60, 80, 100, 120],
        });
        chart.scale("ll", {
            ticks: yAxis,
            //ticks: ['0.0', '4.0', '8.0', '12.0', '16.0', '20.0', '24.0'],
        });
        chart.scale("ct", {
            ticks: yAxis,
            //ticks: ['0.0', '4.0', '8.0', '12.0', '16.0', '20.0', '24.0'],
        });
        // 关闭提示
        chart.tooltip(false);

        // Step 3：创建图形
        chart.line().position('time*ynd').color('green').shape('line');
        chart.point().position('time*ynd').color('green').shape('ynd_text');
        chart.line().position('time*xy').color('gold').shape('line');
        chart.point().position('time*xy').color('gold').shape('xy_text');
        chart.line().position('time*ll').color('blue').shape('line');
        chart.point().position('time*ll').color('blue').shape('ll_text');
        chart.line().position('time*ct').color('red').shape('line');
        chart.point().position('time*ct').color('red').shape('ct_text');

        // Step 4: 渲染图表
        chart.render();
        chart.interaction("pan");
    }

    function year_chart(xAxis, yAxis, data) {
        // Step 1: 创建 Chart 对象
        const chart = new F2.Chart({
            id: 'myChart',
            width: screen.availWidth,
            height: 300,
            //padding: 'auto', // 默认值，自动计算 padding
            plugins: [ScrollBar, pan],
            pixelRatio: window.devicePixelRatio // 指定分辨率
        });

        // Step 2: 载入数据源
        chart.source(data);

        // 关闭图例
        chart.legend(false);

        // 设置X轴属性
        chart.axis('time', { label: { fontSize: 12 }, grid: null });
        // 设置Y轴属性
        chart.axis('ynd', {
            label: {
                fontSize: 12
            },
            grid: () => {
                return {
                    lineWidth: 1,
                    lineDash: null,
                    stroke: 'gray'
                }
            }
        });
        chart.axis('xy', { label: { fontSize: 12 }, grid: null });
        chart.axis('ll', { label: { fontSize: 12 }, grid: null });
        chart.axis('ct', { label: { fontSize: 12 }, grid: null });

        // 改变X轴刻度值
        chart.scale("time", {
            ticks: xAxis,
            values: xAxis.slice(-7)
        });
        // 改变Y轴刻度值
        chart.scale("ynd", {
            min: 0,//手动设置最小值
            ticks: [0, 20, 40, 60, 80, 100, 120],
        });
        chart.scale("xy", {
            min: 0,//手动设置最小值
            ticks: [0, 20, 40, 60, 80, 100, 120],
        });
        chart.scale("ll", {
            ticks: yAxis,
            //ticks: ['0.0', '4.0', '8.0', '12.0', '16.0', '20.0', '24.0'],
        });
        chart.scale("ct", {
            ticks: yAxis,
            //ticks: ['0.0', '4.0', '8.0', '12.0', '16.0', '20.0', '24.0'],
        });
        // 关闭提示
        chart.tooltip(false);

        // Step 3：创建图形
        chart.line().position('time*ynd').color('green').shape('line');
        chart.point().position('time*ynd').color('green').shape('ynd_text');
        chart.line().position('time*xy').color('gold').shape('line');
        chart.point().position('time*xy').color('gold').shape('xy_text');
        chart.line().position('time*ll').color('blue').shape('line');
        chart.point().position('time*ll').color('blue').shape('ll_text');
        chart.line().position('time*ct').color('red').shape('line');
        chart.point().position('time*ct').color('red').shape('ct_text');

        // Step 4: 渲染图表
        chart.render();
        chart.interaction("pan");
    }
</script>

<style scoped lang="scss">
    .weui-navbar{
        background-color: #FFFFFF;
    }
    .weui-navbar:after{
        border-bottom: none;
    }
    .weui-navbar__item:after{
        border-right: none;
    }
    .weui-navbar__item.weui-bar__item_on{
        background-color: #F5F5F5;
    }
</style>
